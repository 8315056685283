import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class PurchaseItemPriceService {
    readPurchaseItemPriceQuery(){
        var query = `
            query ( $paging: ServerPaging) {
                GetProductMaster (Paging: $paging) {
                    ProductMaster {
                        item_id
                        product_code
                        item_name
                        item_type
                        type_detail_id
                        purchase_item_price {
                            price_id
                            item_id
                            purchase_price
                            end_date
                            created_at
                            last_update
                      }
                    }
                    Total
                }
            }
        `;
        return query;
    }

    async editQuery(variables){
        var query = gql`
            mutation ( $id: String!, $data: NewPurchaseItemPrice! ) {
                UpdatePurchaseItemPrice ( ItemID: $id, New: $data) 
            }
        `;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

}

export default new PurchaseItemPriceService();