<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="stock"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import stockServices from '../Script/StockServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable.js';

export default {
    name: 'StockGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['StorageId', 'TypeDetail'],
    computed: {
        stock () {
            var StorageId = this.StorageId;
            var TypeDetail = this.TypeDetail == null ? null : this.TypeDetail.value;
            
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: stockServices.readStockQuery(),
                                variables : {
                                    id : StorageId, 
                                    paging : paging,
                                    typeDetail : TypeDetail
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetItemStockV2.item_stock == null) {
                            return [];
                        }
                        else {
                            return response.data.GetItemStockV2.item_stock;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetItemStockV2.total == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetItemStockV2.total;
                        }
                    },
                    model: {
                        fields: {
                            weight: { type: "number"},
                        }
                    }
                }
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "storage_name", title: "Nama Storage", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "product_code", title: "Kode Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "barcode", title: "Bar Code", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_name", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionButton("Stock Gudang")
        },
        detailInit: function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "permissionGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.item_stock_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    schema: {
                        data: function (response) {
                            return response == null ? [] : response;
                        },
                        total: function(response) {
                            return response == null ? 0 : response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                resizable: true,
                columns: [
                    { field: 'stock', title: "Stock", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                    // { field: 'base_uom', title: "Satuan Awal", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'uom', title: "Satuan Akhir", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    // { field: 'weight', title: "Berat Satuan", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                ],
            })
        },
        async excel(storageIdData) {
            var grid = this.$refs.grid.kendoWidget().dataSource;
            
            const paging = {
                Filter : globalfunc.filterQueryBuilder(grid._filter),
                OrderBy : globalfunc.sortingQueryBuilder(grid._sort)
            }

            var storageId = this.StorageId;
            var storageName = storageIdData.filter(item => item.value == storageId);

            var typeDetail = this.TypeDetail == null ? null : this.TypeDetail.value;
            var typeDetailName = this.TypeDetail == null ? 'All' : this.TypeDetail.label;
            
            var data = await stockServices.getDetailStockGudangExcel(paging, storageId, typeDetail);
            var json = stockServices.stockGudangExcel(data, storageName[0].label, typeDetailName);
            return json;
        },
    },
    
}
</script>