import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class StockSalesService {
    readStockQuery(){
        var query = `query ($id:String, $paging:ServerPaging, $typeDetail:String) {
                GetItemStockSalesV2 (StorageId:$id, Paging:$paging, TypeDetailId:$typeDetail) {
                    item_stock_sales {
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        type_detail_id
                        type_detail_name
                        item_type
                        base_uom
                        weight
                        stock
                        item_stock_sales_detail {
                            storage_id
                            storage_name
                            item_id
                            product_code
                            item_name
                            barcode
                            item_type
                            base_uom
                            uom
                            conv_amount
                            weight
                            stock
                      }
                    }
                    total
                }
            }
        `;
        return query;
    }

    readStockDetailQuery(){
        var query = gql`query ($id:String, $paging:ServerPaging, $typeDetail:String) {
                GetItemStockSalesV2 (StorageId:$id, Paging:$paging, TypeDetailId:$typeDetail) {
                    item_stock_sales {
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        type_detail_id
                        type_detail_name
                        item_type
                        base_uom
                        weight
                        stock
                        item_stock_sales_detail {
                            storage_id
                            storage_name
                            item_id
                            product_code
                            item_name
                            barcode
                            item_type
                            base_uom
                            uom
                            conv_amount
                            weight
                            stock
                      }
                    }
                    total
                }
            }
        `;
        return query;
    }

    async getStorageId(){
        var query = gql`
            query{
                GetStorage{
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var label = result.data.GetStorage[i].storage_id + ' (' + result.data.GetStorage[i].storage_name + ')'
            var str = {value:result.data.GetStorage[i].storage_id, label:label}
            storageData.push(str);
        }
        return storageData
    }

    async readTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Project","Retail"]){
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTypeDetail;
        var typeDetailData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    value: data[i].type_detail_id, 
                    label: data[i].type_detail_id + ' (' + data[i].type_detail_name + ')'
                }
                typeDetailData.push(str);
            }
        }
        return typeDetailData
    }

    async getDetailStockSalesExcel(paging, stockId, typeDetail){
        var variables = {
            id : stockId, 
            paging : paging,
            typeDetail : typeDetail
        }

        var query = this.readStockDetailQuery();
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetItemStockSalesV2.item_stock_sales;
    }

    stockSalesExcelHeaderSize(){
        var size = [
            {width: 75}, //colA
            {width: 25}, //colB
            {width: 25}, //colC
        ];

        return size;
    }

    stockSalesExcel(data, stockName, typeDetailName){
        var arrayObject = [];

        //Row 1
        var row1 = {
            colA : 'PT BOJONG WESTPLAS',
            colB : '',
            colC : ''
        };
        arrayObject.push(row1);

        //Row 2
        var row2 = {
            colA : 'STOCK SALES - ' + stockName,
            colB : '',
            colC : ''
        };
        arrayObject.push(row2);

        //Row 3
        var row3 = {
            colA : 'TIPE DETAIL : ' + typeDetailName,
            colB : '',
            colC : ''
        };
        arrayObject.push(row3);

        //Row 4
        var row4 = {
            colA : '',
            colB : '',
            colC : ''
        };
        arrayObject.push(row4);

        //Row 5
        var row5 = {
            colA : 'Nama Item',
            colB : 'Stock',
            colC : 'Satuan Akhir'
        };
        arrayObject.push(row5);

        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var detailData = data[i].item_stock_sales_detail;

                for (let j = 0; j < detailData.length; j++){
                    var row =  {    
                            colA : data[i].item_name,
                            colB : detailData[j].stock,
                            colC : detailData[j].uom
                        }
                    arrayObject.push(row);
                }
            }
        }
        
        return arrayObject;
    }
}

export default new StockSalesService();
