<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Stock Sales</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Gudang</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <CSelect id="StorageId" class="font-weight-bold " :options="StorageIdData" :value.sync="StorageId" />
                    </div>
                    <div class="col-sm-label">
                        <label class="form-label font-weight-bold">Tipe Detail</label>
                        <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                    </div>
                    <div class="col-sm-content">
                        <v-select id="TypeDetail" class="pb-3" :options="TypeDetailData" v-model="TypeDetail" />
                    </div>
                </CRow>
                <hr>
                <CRow >
                    <CCol class="sm-3">
                        <button type="button" class= "btn btn-primary" v-on:click="exportExcel">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                    </CCol>
                </CRow>
                <hr>
                <stock-grid ref="grid" :key="gridReload" v-bind:storage-id="this.StorageId" v-bind:type-detail="this.TypeDetail"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import '@progress/kendo-ui';
import '@progress/kendo-theme-default/dist/all.css';
import stockSalesService from '../StockSales/Script/StockSalesServices.js';
import stockSalesGrid from '../StockSales/Grid/StockSalesGrid.vue';
import moment from 'moment';

export default {
    name: 'StockSales',
    components: {
        'stock-grid': stockSalesGrid,
    },
    async mounted () {
        this.StorageIdData = await stockSalesService.getStorageId();
        this.StorageId = this.StorageIdData[0].value;
        
        this.TypeDetailData = await stockSalesService.readTypeDetailQuery();
        this.TypeDetail = null;
    },
    data(){
        return{
            StorageId: '',
            StorageIdData : [],
            
            TypeDetail: null,
            TypeDetailData: [],
            gridReload: 0,
        }
    },
    methods: {
        reload(){
            this.gridReload++;
        },
        async exportExcel() {
            var XLSX = require("xlsx");
            var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

            var filename = 'StockSales_' + dateNow + '.xlsx';
            var data = await this.$refs.grid.excel(this.StorageIdData);
            var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
            ws['!cols'] = stockSalesService.stockSalesExcelHeaderSize();
            
            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, ws);
            XLSX.writeFile(wb, filename);
        }
    }
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>