import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class StockService {
    readStockQuery(){
        var query = `
            query ($id:String, $paging:ServerPaging, $typeDetail:String){
                GetItemStockV2 (StorageId:$id, Paging:$paging, TypeDetailId:$typeDetail) {
                    item_stock {
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        type_detail_id
                        type_detail_name
                        base_uom
                        weight
                        stock
                        item_stock_detail{
                            storage_id
                            storage_name
                            item_id
                            product_code
                            item_name
                            barcode
                            item_type
                            base_uom
                            uom
                            weight
                            stock
                        }
                    }
                    total
                }
            }
        `;
        return query;
    }

    readStockDetailQuery(){
        var query = gql`
            query ($id:String, $paging:ServerPaging, $typeDetail:String){
                GetItemStockV2 (StorageId:$id, Paging:$paging, TypeDetailId:$typeDetail) {
                    item_stock {
                        storage_id
                        storage_name
                        item_id
                        product_code
                        item_name
                        barcode
                        item_type
                        type_detail_id
                        type_detail_name
                        base_uom
                        weight
                        stock
                        item_stock_detail{
                            storage_id
                            storage_name
                            item_id
                            product_code
                            item_name
                            barcode
                            item_type
                            base_uom
                            uom
                            weight
                            stock
                        }
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    async getDetailStockGudangExcel(paging, storageId, typeDetail){
        var variables = {
            id : storageId, 
            paging : paging,
            typeDetail : typeDetail
        }

        var query = this.readStockDetailQuery();
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        return result.data.GetItemStockV2.item_stock;
    }

    async getStorageId(){
        var query = gql`
            query{
                GetStorage{
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var str = { value: result.data.GetStorage[i].storage_id, 
                        label: result.data.GetStorage[i].storage_id + ' (' + result.data.GetStorage[i].storage_name + ')'}
            storageData.push(str);
        }
        return storageData
    }

    async readTypeDetailQuery(){
        var query = gql`query{
            GetTypeDetail(Flags:["All","Gudang"]){
                type_detail_id
                type_detail_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetTypeDetail;
        var typeDetailData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { 
                    value: data[i].type_detail_id, 
                    label: data[i].type_detail_id + ' (' + data[i].type_detail_name + ')'
                }
                typeDetailData.push(str);
            }
        }
        return typeDetailData
    }

    
    stockGudangExcelHeaderSize(){
        var size = [
            {width: 75}, //colA
            {width: 25}, //colB
            {width: 25}, //colC
            {width: 25}, //colD
            {width: 25}, //colE
            {width: 25}, //colF
            {width: 25}, //colG
            {width: 25}, //colH
            {width: 25}, //colI
            {width: 25}, //colJ
            {width: 25}, //colK
        ];

        return size;
    }

    stockGudangExcel(data, storageName, typeDetailName){
        var arrayObject = [];

        //Row 1
        var row1 = {
            colA : 'PT BOJONG WESTPLAS',
            colB : '',
            colC : ''
        };
        arrayObject.push(row1);

        //Row 2
        var row2 = {
            colA : 'STOCK GUDANG - ' + storageName,
            colB : '',
            colC : ''
        };
        arrayObject.push(row2);

        //Row 3
        var row3 = {
            colA : 'TIPE DETAIL : ' + typeDetailName,
            colB : '',
            colC : ''
        };
        arrayObject.push(row3);

        //Row 4
        var row4 = {
            colA : '',
            colB : '',
            colC : ''
        };
        arrayObject.push(row4);

        //Row 5
        var row5 = {
            colA : 'Nama Item',
            colB : 'Stock',
            colC : 'Satuan Akhir'
        };
        arrayObject.push(row5);

        if(data != null){
            for (let i= 0; i < data.length; i++) {
                var detail = data[i].item_stock_detail

                for (let j= 0; j < detail.length; j++) {
                    var row = {
                        colA : detail[j].item_name,
                        colB : detail[j].stock,
                        colC : detail[j].uom,
                    };
                    arrayObject.push(row);
                }
            }
        }

        return arrayObject;
    }
}

export default new StockService();