<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="PurchaseItemPrice"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
        >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import PurchaseItemPriceServices from '../Script/PurchaseItemPrice.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'StockGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'StockId'],
    computed: {
        PurchaseItemPrice () {
            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            return { 
                                query: PurchaseItemPriceServices.readPurchaseItemPriceQuery(),
                                variables : {
                                    paging : paging
                                }
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetProductMaster.ProductMaster == null) {
                            return [];
                        }
                        else {
                            return response.data.GetProductMaster.ProductMaster;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetProductMaster.Total == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetProductMaster.Total;
                        }
                    }
                }
            })
        }
    },
    data: function () {
        return  {
            columns:  [
                { title: "Action", width: 100, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "product_code", title: "Kode Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "type_detail_id", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "purchase_item_price.purchase_price", title: "Harga Pembelian Barang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}, 
                    format:"{0:N2}" 
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var editClick = this.$props.editClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            const data = {
                item_id : dataItem.item_id,
                item_code : dataItem.product_code,
                item_name : dataItem.item_name,
                purchase_price : dataItem.purchase_item_price.purchase_price,
            };
            
            editClick(data, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            const data = {
                item_id : dataItem.item_id,
                item_code : dataItem.product_code,
                item_name : dataItem.item_name,
                purchase_price : dataItem.purchase_item_price.purchase_price,
            };

            editClick(data, true);
        })
    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionWithoutDeleteButton("Purchase Price")
        },
    },
    
}
</script>